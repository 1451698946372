<template>
  <div class="section">
    <div class="container">
      <SectionHeader title="AutoCheck Batch" title-icon="fal fa-object-group" />
      <div class="columns is-multiline" v-if="batch">
        <a class="column" @click="setFilter('Warning')">
          <SummaryBox
            label="Warning"
            :value="statusCount('Warning')"
            icon-color="has-text-danger"
            icon-class="fal fa-exclamation-triangle"
          />
        </a>
        <a class="column" @click="setFilter('Caution')">
          <SummaryBox
            label="Caution"
            :value="statusCount('Caution')"
            icon-color="has-text-warning"
            icon-class="fal fa-frown-open"
          />
        </a>
        <a class="column" @click="setFilter('Clear')">
          <SummaryBox
            label="Clear"
            :value="statusCount('Clear')"
            icon-color="has-text-success"
            icon-class="fal fa-check-circle"
          />
        </a>
        <a class="column" @click="setFilter(false)">
          <SummaryBox
            :label="
              `Total ${
                failedCount.length ? `(excl. ${failedCount.length} failed)` : ''
              }`
            "
            :value="rows.length - failedCount.length"
            icon-color="has-text-primary"
            icon-class="fal fa-car"
          />
        </a>
      </div>
      <div class="buttons is-right">
        <button
          class="button is-small is-primary"
          :class="{ 'is-loading': $wait.is('pdf-download') }"
          :disabled="$wait.is('pdf-download') || !pdfUuids.length"
          @click="downloadPdfs"
        >
          <span class="icon">
            <i class="fal fa-file-certificate" />
          </span>
          <span>
            Download PDFs
          </span>
        </button>
      </div>
      <table class="table is-size-7-touch is-striped is-fullwidth">
        <thead>
          <td>
            VRM
          </td>
          <td class="is-hidden-mobile">
            VIN
          </td>
          <td>
            Description
          </td>
          <td>
            Status
          </td>
          <td>
            <div class="columns is-mobile filter-menu-dropdown">
              <div class="column">
                <span class="radio-tick">
                  <checkbox v-model="allSelected" />
                </span>
              </div>
            </div>
          </td>
        </thead>
        <tbody v-if="batch">
          <GridTooltip
            id="grid-tooltip"
            style="display: none"
            v-tippy-html
            v-bind="{ grid }"
          />
          <tr
            v-for="(check, index) in currentRows"
            :key="index"
            :class="{ 'is-failed': !check.response }"
          >
            <td>
              <router-link
                :to="getLink(check)"
                v-if="check.vrm && check.response"
                class="button is-size-7-mobile"
              >
                {{ check.vrm }}
              </router-link>
              <button
                v-else-if="check.vrm"
                class="button is-size-7-mobile "
                disabled
              >
                {{ check.vrm }}
              </button>
            </td>
            <td class="is-hidden-mobile">
              {{ check.vin }}
            </td>
            <td>
              <span v-if="check.response">
                {{ check.description }}
              </span>
              <span class="has-text-grey-light" v-else>
                <span>N/A</span>
                <span
                  v-tippy="{ placement: 'right' }"
                  title="Failed to perform a check"
                  class="icon has-text-danger"
                >
                  <i class="fal fa-exclamation-circle" />
                </span>
              </span>
            </td>
            <td>
              <a
                v-if="check.response"
                @mouseover="updateTooltip(check.response)"
                v-tippy="{ html: '#grid-tooltip' }"
              >
                <span
                  class="tag is-medium is-size-7-mobile has-text-weight-bold"
                  :class="`is-${check.status.class}`"
                >
                  {{ check.status.text }}
                </span>
              </a>
              <span class="has-text-grey-light" v-else>N/A</span>
            </td>
            <td>
              <div class="columns is-mobile filter-menu-dropdown">
                <div class="column">
                  <span class="radio-tick">
                    <checkbox
                      v-if="check.response"
                      v-model="pdfUuids"
                      :value="check.response.uuid"
                    />
                    <checkbox v-else disabled class="is-disabled" />
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <TableEmpty
        text="No AutoChecks for this status"
        v-if="batch && filter && !currentRows.length"
        size="medium"
      />
    </div>
  </div>
</template>

<script>
import store from 'modules/autocheck/stores'
import registerModule from '@/store/services'
import * as AutoCheck from '@/modules/autocheck/services'
import { getBatchPdf } from 'modules/autocheck/services/pdfs'
import GridTooltip from 'modules/autocheck/components/AutoCheckGridTooltip'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BatchCheckSummary',
  components: {
    SummaryBox: () => import('core/components/SummaryBox'),
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    TableEmpty: () => import('core/components/TableEmpty'),
    GridTooltip
  },
  data: () => ({
    filter: false,
    allSelected: false,
    pdfUuids: [],
    tooltipData: null
  }),
  async created() {
    registerModule('autocheck', store)
  },
  async mounted() {
    const { uuid } = this.$route.params
    try {
      await this.fetchBatch(uuid)
      this.allSelected = true
    } catch {
      this.$notify('Batch unavailable')
    }
  },
  watch: {
    allSelected: 'toggleAll'
  },
  computed: {
    ...mapGetters('autocheck', ['batch']),
    grid() {
      return AutoCheck.getGrid(this.tooltipData)
    },
    rows() {
      return this.batch?.requests.map(item => {
        if (typeof item.response?.response === 'string') {
          item.response.response = JSON.parse(item.response.response)
          item.status = this.getStatus(item.response.response)
          item.description = this.buildDescription(item.response.response)
        }
        return item
      })
    },
    currentRows() {
      const { filter, rows } = this
      if (filter) return rows.filter(row => row.status?.text === filter)
      else return this.rows
    },
    failedCount() {
      return this.rows.filter(row => !row.response)
    }
  },
  methods: {
    ...mapActions('autocheck', ['fetchBatch']),
    getStatus: check => AutoCheck.getStatus(check),
    buildDescription: check => AutoCheck.buildDescription(check),
    statusCount(status) {
      return this.rows.filter(row => row.status?.text === status).length
    },
    getLink(check) {
      return { name: 'autocheck', params: { uuid: check.response?.uuid } }
    },
    toggleAll() {
      this.allSelected ? this.setAllPdfUuids() : (this.pdfUuids = [])
    },
    setAllPdfUuids() {
      this.pdfUuids = this.currentRows.map(row => row.response?.uuid)
    },
    setFilter(filter) {
      this.filter = filter
      this.allSelected = true
      this.setAllPdfUuids()
    },
    getCheckDataByUuid(uuid) {
      return {
        request: this.currentRows.find(row => row.response?.uuid === uuid)
      }
    },
    async downloadPdfs() {
      try {
        this.$wait.start('pdf-download')
        const checks = this.pdfUuids.map(this.getCheckDataByUuid)
        getBatchPdf(checks)
      } catch {
        this.$notify('Unable to download your PDFs right now')
      } finally {
        this.$wait.end('pdf-download')
      }
    },
    updateTooltip({ response }) {
      this.tooltipData = response
    }
  }
}
</script>

<style lang="sass" scoped>
.is-failed
  opacity: .5
.is-disabled
  pointer-events: none
</style>
